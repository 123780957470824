<template>
<div id="data-sources">
<b-modal id="report-settings" @hide="resetForm" title="Report Setttings" hide-footer size="md">
<b-form>
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div>
        <div>
            <b-form-group
            id="base_table"
            label="Base Data Source">
            <b-input-group>
                <multiselect
                    openDirection="bottom"
                    v-model="base_table" 
                    :options="tableNames"
                    placeholder="Select the base data source" 
                    :searchable="true"
                    :show-labels="false"
                    :allow-empty="false"
                    label="name"
                    :internalSearch="true">
                </multiselect>
            </b-input-group>
            </b-form-group>
        </div>
        <div v-if="base_table">
            <b-form-group
            id="report_type"
            label="Report Type">
            <b-input-group>
                <multiselect
                    openDirection="bottom"
                    v-model="report_type" 
                    :options="report_type_options"
                    placeholder="Select the type of report" 
                    :searchable="true"
                    :show-labels="false"
                    :allow-empty="false"
                    label="name"
                    :internalSearch="true">
                </multiselect>
            </b-input-group>
            </b-form-group>
        </div>
        <div class="mt-4" style="text-align:right">
            <b-button @click="hideModal()" class="avo-basic-btn mr-2">
                Cancel
            </b-button>
            <b-button @click="submitDataSources()" variant="outline-primary" :disabled="!base_table || !report_type" class="avo-primary-btn">
                Submit Report Settings
            </b-button>
        </div>
    </div>
</b-form>
</b-modal>
</div>
</template>

<script>
import { alertsMixin } from '../../common.js'
import Multiselect from 'vue-multiselect'

export default {
  name: 'ReportDataSourcesModal',
  components: {
      Multiselect
  },
  mixins: [alertsMixin],
  data: function(){
      return {
          base_table: null,
          report_type: null,
      }
  },
  methods: {
    submitDataSources(){
        this.$store.commit("reports/clearCustomReport");
        if (this.report_type['value'] != this.base_table['value']){
            this.$store.commit("reports/setBaseTable", this.report_type);
        }
        else{
            this.$store.commit("reports/setBaseTable", this.base_table);
        }
        this.hideModal();
    },
    resetForm(){
        this.base_table = null;
    },
    hideModal(){
        this.$bvModal.hide('report-settings');
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
  computed: {
      reportMap: function(){
          return this.$store.state.reports.report_map;
      },
      tableNames: function(){
          var names = []
          if (this.reportMap){
              for (var key in this.reportMap){
                if (this.reportMap[key]['main_table']){
                    names.push(
                        {
                            'name': this.reportMap[key]["name"],
                            'value': key
                        }
                    )
                }
              }
          }
          return names
      },
      report_type_options: function(){
          var report_types = [];
          if (this.base_table){
              for (let key in this.reportMap[this.base_table['value']]["report_types"]){
                  report_types.push({
                      'name': this.reportMap[this.base_table['value']]["report_types"][key],
                      'value': key
                  })
              }
          }
          return report_types;
      }

  },
  watch: {
      base_table: function(){
          this.report_type = null;
      },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>

</style>
<template>
    <b-card>
        <div class="d-flex justify-content-between">
            <div class="avo-header-text">
                {{report.title}}
            </div>
            <div>

                <div v-if="!showKebab">
                    <b-button v-if="!exportOnly && !report.standard" class="avo-primary-btn mr-2" @click="viewReportAssignmentModal()">Manage Report Assignments</b-button>
                    <b-button class="avo-primary-btn" @click="exportReport()">Run CSV export</b-button>
                    <b-modal ref="report-exporting-modal" hide-footer title="Report exporting">
                    <div class="d-block text-center">
                        Your report is processing in the background, you may navigate away. Your report will appear here when ready.
                    </div>
                    </b-modal>

                </div>
                <b-dropdown v-if="showKebab" class="avo-action-dropdown" no-caret style="min-width:none;height:20px;margin-top:-20px" :right="true">
                    <template #button-content>
                    <b-icon icon="three-dots"></b-icon>
                    </template>
                    <b-dropdown-item v-if="!exportOnly" @click="renameReport()">Rename</b-dropdown-item>
                    <b-dropdown-item v-if="!exportOnly" @click="editReport()">Edit</b-dropdown-item>
                    <b-dropdown-item v-if="!exportOnly" @click="cloneReport()">Clone</b-dropdown-item>
                    <b-dropdown-item @click="selectReport()">View CSV exports</b-dropdown-item>
                    <b-dropdown-item v-if="!exportOnly" @click="deleteReport()">Delete</b-dropdown-item>
                </b-dropdown>
                <b-modal :id="'rename-modal-'+report.report_id" hide-footer hide-header size="md">
                    <div class="avo-header-text" style="text-align:center">
                        Rename '{{report.title}}'
                    </div>
                    <hr class="mt-2">
                    <div class="mt-2">
                        <b-alert
                            v-model="showAlert"
                            variant="danger"
                            dismissible>
                            <b>{{alertMessage}}</b>
                        </b-alert>
                    </div>
                    <div>
                        <b-form-group id="title" label="Title">
                            <b-input-group>
                            <b-form-input v-model="newTitle"></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </div>
                    <div>
                        <b-form-group id="description" label="Description">
                            <b-input-group>
                            <b-form-textarea v-model="newDescription"></b-form-textarea>
                            </b-input-group>
                        </b-form-group>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div>
                            <b-button @click="hideRename()" class="avo-basic-btn">Cancel</b-button>
                        </div>
                        <div class="ml-2">
                            <b-button @click="submitRename()" class="avo-primary-btn">Rename Report</b-button>
                        </div>
                    </div>
                </b-modal>
            </div>
        </div>
        <div v-if="report.description" class="avo-text-light-small mt-2">
            {{report.description}}
        </div>
        <hr>
        <div class="d-flex">
            <div class="d-flex flex-column mr-2">
                <div class="avo-text-light">
                    Created by
                </div>
                <div>
                    <b-avatar :text="report.initials" size=20px />
                    {{report.name}}
                </div>
            </div>
            <div class="d-flex flex-column ml-2">
                <div class="avo-text-light">
                    Last updated by
                </div>
                <div>
                    <b-avatar :text="report.initials" report.initials size=20px />
                    {{report.name}}
                    at {{datetimeFormat(report.updated)}}
                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'

export default {
  name: 'ReportCard',
  mixins: [alertsMixin],
  props: {
      report: Object, 
      showKebab: {  type: Boolean,
                    default: true,
                 },
      exportOnly: {
          type: Boolean,
          default: false
      }
  },
  data(){
      return {
        newTitle: "",
        newDescription: "",
      }
  },
  methods: {
    datetimeFormat(datetime){
        var formattedDatetime = "-"
        if (datetime){
            var date = datetime.split("T")[0].split("-");
            var time = datetime.split("T")[1].split(":");
            var time_suffix = "AM";
            if (parseInt(time[0]) > 12){
                time[0] = (parseInt(time[0]) - 12).toString();
                time_suffix = "PM"
            }
            formattedDatetime = [date[1], date[2], date[0]].join("/") + " @ " + time[0] + ":" + time[1] + time_suffix; 
        }
        return formattedDatetime
    },
    renameReport(){
        this.newTitle = this.report.title;
        this.newDescription = this.report.description;
        this.$bvModal.show('rename-modal-'+this.report.report_id);
    },
    hideRename(){
        this.hideError();
        this.$bvModal.hide('rename-modal-'+this.report.report_id)
    },
    submitRename(){
        this.hideError();
        if (this.newTitle == ""){
            this.showError("Please enter a title");
            return
        }
        const url = make_url("/reports/"+this.report.report_id).toString();
        const vm = this;
        var data = {
            'title': this.newTitle,
            'description': this.newDescription
        };
        const promise = authenticated_request({
            method: "put",
            url: url,
            data: data
        }).then(function(response){
            if (response) {
                vm.$emit("updated");
                vm.hideRename();
            }
        }).catch(function(error){
            vm.showError(error);
        });
        
        return promise;
    },
    cloneReport(){
        this.hideError();
        const url = make_url("/reports");
        const vm = this;
        var data = {
            'title': this.report.title + " (clone)",
            'description': this.report.description,
            'worker_id': this.user_worker_id,
            'base_table': this.report.base_table,
            'columns': this.report.columns,
            'filters': this.report.filters,
            'standard': this.report.standard,
            'order_by': this.report.order_by,
            'start_date': this.report.start_date,
            'end_date': this.report.end_date
        };
        const promise = authenticated_request({
            method: "post",
            url: url,
            data: data
        }).then(function(response){
            if (response) {
                vm.$emit("updated");
            }
        }).catch(function(error){
            vm.showError(error);
        });
        
        return promise;
    },
    selectReport(){
        this.$emit("selected");
    },
    exportReport(){
        this.hideError();
        this.$refs['report-exporting-modal'].show();
        const url = make_url("/reports/"+this.report.report_id+"/export");
        const vm = this;
        const promise = authenticated_request({
            method: "post",
            url: url,
        }).then(function(response){
            if (response) {
                vm.$emit("exported");
            }
        }).catch(function(error){
            vm.showError(error);
        });
        
        return promise;
    },
    editReport(){
        this.$emit("edit");
    },
    deleteReport(){
        const url = make_url("/reports/"+this.report.report_id).toString();
        const vm = this;
        const promise = authenticated_request({
            method: "delete",
            url: url,
        }).then(function(response){
            if (response) {
                vm.$emit("updated");
            }
        }).catch(function(error){
            vm.showError(error);
        });
        
        return promise;
    },
    viewReportAssignmentModal(){
        this.$store.commit("reports/setReportAssignmentID", this.report.report_id);
        this.$bvModal.show('report-assignments');
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
  computed: {
    user_worker_id: function() {
        return localStorage.getItem("worker_id");
    },
    reportMap: function(){
        return this.$store.state.reports.report_map;
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
<template>
<div id="report-assignments">
<b-modal id="report-assignments" @show="setForm" title="Report Assignments" hide-footer size="md">
<b-form>
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div>
        <div>
            <b-form-group
                label="Assign Practices:">
                <practice-multiselect 
                    v-if="!practicesLoading" 
                    v-model="selectedPractices" 
                    :multiple="true" 
                    :loading="practicesLoading">
                </practice-multiselect>
                <div v-else style="text-align:center">
                    <b-spinner></b-spinner>
                </div>
            </b-form-group>
        </div>
        <div>
            <b-form-group
                label="Assign Providers:">
                <provider-multiselect 
                    v-if="!providersLoading" 
                    v-model="selectedProviders" 
                    :multiple="true" 
                    :loading="providersLoading">
                </provider-multiselect>
                <div v-else style="text-align:center">
                    <b-spinner></b-spinner>
                </div>
            </b-form-group>
            </div>
        <div class="mt-4" style="text-align:right">
            <b-button @click="hideModal()" class="avo-basic-btn mr-2">
                Cancel
            </b-button>
            <b-button @click="unassignPractices()" :disabled="practicesLoading || providersLoading" variant="outline-primary" class="avo-primary-btn">
                Submit Report Assignments
            </b-button>
        </div>
    </div>
</b-form>
</b-modal>
</div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'
import PracticeMultiselect from '../multiselects/PracticeMultiselect.vue'
import ProviderMultiselect from '../multiselects/ProviderMultiselect.vue'

export default {
  name: 'ReportAssignmentModal',
  components: {
      PracticeMultiselect,
      ProviderMultiselect,
  },
  mixins: [alertsMixin],
  data: function(){
      return {
          selectedPractices: [],
          selectedProviders: [],

          practicesLoading: false,
          providersLoading: false,
      }
  },
  methods: {
    unassignPractices(){
        const vm = this;
        const url = make_url("/reports/" + this.reportAssignmentID + "/practices");
        authenticated_request({
            method: "delete",
            url: url,
        }).then(function(response){
            if (response) {
                if (vm.selectedPractices.length > 0){
                    vm.assignPractices();
                }
                else{
                    vm.unassignProviders();
                }
            }
        }).catch(function(error){
            vm.showError(error);
        });
    },
    assignPractices(){
        const vm = this;
        const url = make_url("/reports/" + this.reportAssignmentID + "/practices");
        authenticated_request({
            method: "put",
            url: url,
            data: {
                practice_ids: this.selectedPractices.map(obj => obj.practice_id).join(",")
            }
        }).then(function(response){
            if (response) {
                vm.unassignProviders();
            }
        }).catch(function(error){
            vm.showError(error);
        });
    },
    unassignProviders(){
        const vm = this;
        const url = make_url("/reports/" + this.reportAssignmentID + "/providers");
        authenticated_request({
            method: "delete",
            url: url,
        }).then(function(response){
            if (response) {
                if (vm.selectedProviders.length > 0){
                    vm.assignProviders();
                }
                else{
                    vm.hideModal();
                }
            }
        }).catch(function(error){
            vm.showError(error);
        });
    },
    assignProviders(){
        const vm = this;
        const url = make_url("/reports/" + this.reportAssignmentID + "/providers");
        authenticated_request({
            method: "put",
            url: url,
            data: {
                provider_ids: this.selectedProviders.map(obj => obj.provider_id).join(",")
            }
        }).then(function(response){
            if (response) {
                vm.hideModal();
            }
        }).catch(function(error){
            vm.showError(error);
        });
    },
    setForm(){
        console.log("Setting report assignments");
        if (this.reportAssignmentID){
            this.practicesLoading = true;
            this.providersLoading = true;
            const vm = this;
            var url = make_url("/reports/" + this.reportAssignmentID + "/practices");
            authenticated_request({
                method: "get",
                url: url,
            }).then(function(response){
                if (response) {
                    console.log("Got selected practices data, setting");
                    console.log(response.data)
                    vm.selectedPractices = response.data;
                    vm.practicesLoading = false;
                }
            }).catch(function(error){
                vm.showError(error);
            });
            url = make_url("/reports/" + this.reportAssignmentID + "/providers");
            authenticated_request({
                method: "get",
                url: url,
            }).then(function(response){
                if (response) {
                    vm.selectedProviders = response.data.providers;
                    vm.providersLoading = false;
                }
            }).catch(function(error){
                vm.showError(error);
            });
        }
    },
    hideModal(){
        this.$bvModal.hide('report-assignments');
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
  computed: {
    reportAssignmentID: function(){
        return this.$store.state.reports.report_assignment_id;
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>

</style>
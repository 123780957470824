<template>
<div>
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div v-if="!report_selected"  class="d-flex mb-4">
        <div v-if="!report_selected && !idType" class="avo-page-header-text" style="margin-right:30px">
            Reports
        </div>
        <div v-if="!report_selected" style="margin-right:30px">
                <b-input-group v-if="standard_tab" class="search-group">
                    <b-input-group-prepend>
                        <span class="input-group-text"><b-icon icon="search"></b-icon></span>
                    </b-input-group-prepend>
                    <b-form-input
                        @keyup="debounceReports"
                        @search="debounceReports"
                        class="report-search-input pl-1"
                        type="search" v-model="standard_search"
                        placeholder="Search standard reports"/>
                </b-input-group>
                <b-input-group v-else class="search-group">
                    <b-input-group-prepend>
                        <span class="input-group-text"><b-icon icon="search"></b-icon></span>
                    </b-input-group-prepend>
                    <b-form-input
                        @keyup="debounceCustomReports"
                        @search="debounceCustomReports"
                        class="report-search-input pl-1"
                        type="search" v-model="custom_search"
                        placeholder="Search custom reports"/>
                </b-input-group>
        </div>
        <b-nav tabs v-if="!report_selected && !idType" class="nav-section">
        <b-nav-item :active="standard_tab" @click="standard_tab = true">Standard</b-nav-item>
        <b-nav-item :active="!standard_tab" @click="standard_tab = false">Custom Reports</b-nav-item>
    </b-nav>
    </div>
    <report-assignment-modal />
    <report-document-assignment-modal />
    <b-container style="max-width:100%;padding:30px">
    <br v-if="idType && !report_selected" />
    <div v-if="standard_tab && !report_selected" style="margin-right:-20px;margin-left:-20px;">
        <b-card style="border-radius:15px;min-height:500px;">
            <div class="avo-header-text report-container-header">
                Standard Reports
            </div>
            <b-overlay :show="standardLoading" variant="white">
            <b-row v-for="(report_key,index) in Object.keys(standard_reports)" :key="index" style="padding:30px;padding-bottom:0px">
                <b-col cols="6" class="d-flex flex-column flex-fill">
                    <div class="avo-header-text">
                        {{report_key}}
                    </div>
                </b-col>
                <b-col cols="6" class="d-flex flex-column flex-fill">
                    <div v-for="(report,report_index) in standard_reports[report_key]" :key="report_index">
                        <b-dropdown class="avo-action-dropdown" no-caret style="min-width:none;height:20px;margin-top:-20px" :right="true">
                            <template #button-content>
                            <span style="font-size:16px">{{report['title']}}</span>
                            </template>
                            <b-dropdown-item @click="editReport(report)">Modify</b-dropdown-item>
                            <b-dropdown-item @click="selectReport(report)">View CSV exports</b-dropdown-item>
                        </b-dropdown>
                        <hr>
                    </div>
                </b-col>
            </b-row>
            </b-overlay>
        </b-card>
    </div>
    <div v-else-if="report_selected" style="margin-right:-20px;margin-left:-20px"> 
        <b-button @click="selectReport(null)" variant="outline-primary" class="avo-basic-btn mt-3 mb-3"><i class="fas fa-chevron-left mr-2"></i> Back to reports</b-button>
        <report-card :report="selected_report" @exported="exportReport()" @edit="editReport(selected_report)" :showKebab="false" :exportOnly="!!idType"/>
        <document-list idType="report" :id="selected_report.report_id" perPage="6" mode="table" :key="document_list_key" :hideDropdown="!!idType"/>
    </div>
    <div v-else style="margin-right:-20px;margin-left:-20px">
        <b-card style="border-radius:15px;min-height:500px;">
            <div class="d-flex justify-content-between report-container-header">
                <div class="avo-header-text">
                    Custom Reports
                </div>
                <div>
                    <b-button v-if="!idType" v-b-modal.report-settings class="avo-primary-btn">
                        <b-overlay :show="customMapLoading" variant="white">
                        Create New Report
                        </b-overlay>
                    </b-button>
                    <report-settings-modal />
                </div>
            </div>
            <b-overlay :show="customLoading" variant="white">
            <div v-if="recent_reports.length > 0" class="mt-2" style="padding:30px">
                <div class="avo-header-text">
                    Recently Created
                </div>
                <div class="d-flex flex-wrap mt-4">
                    <div v-for="(report, index) in recent_reports" :key="report.report_id" :class="index > 1 ? 'mt-4' : ''" style="width:50%">
                        <report-card :class="(index % 2) ? 'ml-4' : 'mr-4'" :report="report" @selected="selectReport(report)" @edit="editReport(report)" @updated="getCustomReports()" :exportOnly="!!idType"  />
                    </div>
                </div>
            </div>
            <div v-if="last_month_reports.length > 0" class="mt-2" style="padding:30px">
                <div class="avo-header-text">
                    Created Last Month
                </div>
                <div class="d-flex flex-wrap mt-4">
                    <div v-for="(report, index) in last_month_reports" :key="report.report_id" :class="index > 1 ? 'mt-4' : ''" style="width:50%">
                        <report-card :class="(index % 2) ? 'ml-4' : 'mr-4'" :report="report" @selected="selectReport(report)" @edit="editReport(report)" @updated="getCustomReports()" :exportOnly="!!idType" />
                    </div>
                </div>
            </div>
            <div v-if="before_last_month_reports.length > 0" class="mt-2" style="padding:30px">
                <div class="avo-header-text">
                    Created before Last Month
                </div>
                <div class="d-flex flex-wrap mt-4">
                    <div v-for="(report, index) in before_last_month_reports" :key="report.report_id" :class="index > 1 ? 'mt-4' : ''" style="width:50%">
                        <report-card :class="(index % 2) ? 'ml-4' : 'mr-4'" :report="report" @selected="selectReport(report)" @edit="editReport(report)" @updated="getCustomReports()" :exportOnly="!!idType" />
                    </div>
                </div>
            </div>
            <div v-if="(recent_reports.length == 0) && (last_month_reports.length == 0) && (before_last_month_reports.length == 0)" 
            class="avo-header-text mt-2" style="padding:30px;text-align:center">
                No Reports Found
            </div>
            </b-overlay>
        </b-card>
    </div>
    </b-container>
</div>
</template>

<script>
import { make_url, authenticated_request, debounce, alertsMixin } from '../../common.js'
import ReportCard from './ReportCard.vue'
import ReportSettingsModal from './ReportSettingsModal.vue'
import DocumentList from '../documents/DocumentList.vue'
import ReportAssignmentModal from './ReportAssignmentModal.vue'
import ReportDocumentAssignmentModal from './ReportDocumentAssignmentModal.vue'

export default {
  name: 'ReportList',
  props: ['id', 'idType'],
  mixins: [alertsMixin],
  components: {
      ReportCard,
      ReportSettingsModal,
      DocumentList,
      ReportAssignmentModal,
      ReportDocumentAssignmentModal
  },
  data(){
      return {
        standard_tab: true,
        report_selected: false,
        standard_search: "",
        custom_search: "",
        standard_reports: {},
        recent_reports: [],
        last_month_reports: [],
        before_last_month_reports: [],
        selected_report: null,
        perPage: 20,
        totalRows: 1,
        page: 1,

        document_list_key: 1,

        standardLoading: true,
        customLoading: true,
        customMapLoading: true,
      }
  },
  methods: {
    getReports: function(){
        if (!this.customLoading){
            this.customLoading = true;
        }
        var params = {
            'title': this.standard_search,
            'standard': true
        };
        params.per_page = this.perPage;
        params.page = this.page;
        const url = make_url("/reports");
        const vm = this;
        const promise = authenticated_request({
            method: "get",
            url: url,
            params: params
        }).then(function(response){
            if (response) {
                const reports = response.data.reports;
                var standard_reports = {};
                for (var i = 0; i < reports.length; i++){
                    if (!standard_reports[reports[i]['standard_group']]){
                        standard_reports[reports[i]['standard_group']] = [];
                    }
                    standard_reports[reports[i]['standard_group']].push(reports[i]);
                }
                vm.standard_reports = standard_reports;
                return standard_reports || {};
            }
        }).catch(function(error){
            vm.showError(error);
        }).finally(()=>{
            vm.standardLoading = false;
        });
        
        return promise;
    },
    getCustomReports: function(){
        if (!this.customLoading){
            this.customLoading = true;
        }
        this.recent_reports = [];
        this.last_month_reports = [];
        this.before_last_month_reports = [];
        var params = {
            'title': this.custom_search,
            'standard': false,
            'worker_id': this.user_worker_id
        };
        params.per_page = this.perPage;
        params.page = this.page;
        if (this.idType == 'practice'){
            params.practice_id = this.id;
        }
        else if (this.idType == 'provider'){
            params.provider_id = this.id;
        }
        const url = make_url("/reports");
        const vm = this;
        const promise = authenticated_request({
            method: "get",
            url: url,
            params: params
        }).then(function(response){
            if (response) {
                var last_month = new Date(new Date().setDate(new Date().getDate() - 30));
                var before_last_month = new Date(new Date().setDate(new Date().getDate() - 60));
                const reports = response.data.reports;
                for (var i = 0; i < reports.length; i++){
                    if (reports[i].created){
                        var report_created = new Date(reports[i].created);
                        if (report_created > last_month){
                            vm.recent_reports.push(reports[i]);
                        }
                        else if (report_created > before_last_month){
                            vm.last_month_reports.push(reports[i]);
                        }
                        else{
                            vm.before_last_month_reports.push(reports[i]);
                        }
                    }
                }
                return reports || [];
            }
        }).catch(function(error){
            vm.showError(error);
        }).finally(()=>{
            vm.customLoading = false;
        });
        
        return promise;
    },
    selectReport: function(report){
        this.selected_report = report;
        this.report_selected = !!this.selected_report;
    },
    exportReport: function(){
        const vm = this;
        setTimeout(function(){vm.document_list_key += 1;}, 2000);
    },
    editReport(report){
        var base_table_key = report['base_table'];
        var base_table = {
            "name": this.reportMap[base_table_key]['name'],
            "value": base_table_key,
        }
        var column_split = report['columns'].split(",,");
        var columns = [];
        var column_map = {};
        var column;
        var column_path;
        for (var i = 0; i < column_split.length; i++){
            column_path = column_split[i].split(":")[0].split("/");
            column = column_split[i].split(":")[1];
            if (column_path[1] == "columns"){
                columns.push(this.reportMap[column_path[0]]['columns'][column]);
            }
            else if (column_path[1] == "aggregate_columns"){
                columns.push(this.reportMap[column_path[0]]['aggregate_columns'][column]);
            }
            else if (column_path[1] == "sub_stmt"){
                columns.push(this.reportMap[column_path[0]]['columns'][column]);
            }
            else{
                columns.push(this.reportMap[column_path[0]]['sub_tables'][column_path[1]]["columns"][column]);
            }
            columns[i]['key'] = column
            column_map[column] = columns[i]
        }
        if (this.reportMap[column_path[0]]['filterable_columns']){
            for (let key in this.reportMap[this.reportMap[column_path[0]]['filterable_columns']]['columns']){
                column = this.reportMap[this.reportMap[column_path[0]]['filterable_columns']]['columns'][key];
                column['key'] = key;
                column_map[key] = column;
            }
        }
        var filters = [];
        var filter_split;
        var filter_column_key;
        if (report['filters']){
            var report_filters = report['filters'].split(";");
            for (i = 0; i < report_filters.length; i++){
                filters.push({
                    "column": null,
                    "op": null,
                    "value": null
                })
                filter_split = report_filters[i].split(",,");
                filter_column_key = filter_split[0];
                filters[i]['column'] = column_map[filter_column_key];
                filters[i]['op'] = filter_split[1];
                filters[i]['value'] = filter_split[2];
            }
        }
        var order_by = report['order_by'];

        if (!report.standard){
            this.$store.commit("reports/setReportID", report.report_id);
            this.$store.commit("reports/setReportTitle", report.title);
            this.$store.commit("reports/setReportDescription", report.description)
        }
        this.$store.commit("reports/setBaseTable", base_table);
        this.$store.commit("reports/setColumns", columns);
        this.$store.commit("reports/setFilters", filters);
        this.$store.commit("reports/setOrderBy", order_by);
        this.$store.commit("reports/setStartDate", report.start_date)
        this.$store.commit("reports/setEndDate", report.end_date)
    },
    getCustomReportMap(){
        const url = make_url("/reports/map");
        const vm = this;
        const promise = authenticated_request({
            method: "get",
            url: url,
        }).then(function(response){
            if (response) {
                const report_map = response.data;
                vm.$store.commit("reports/setReportMap", report_map);
            }
        }).catch(function(error){
            vm.showError(error);
        }).finally(()=>{
            vm.customMapLoading = false;
        });
        
        return promise;
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
  computed: {
    user_worker_id: function() {
        return localStorage.getItem("worker_id");
    },
    selected_report_id: function() {
        return this.$store.state.reports.selected_report;
    },
    reportMap: function(){
        return this.$store.state.reports.report_map;
    },
  },
  created(){
    if (this.idType){
        this.standard_tab = false;
    }
    else{
        this.getReports();
        this.debounceReports = debounce(this.getReports, 900);
        this.getCustomReportMap();
    }
    this.getCustomReports();
    this.debounceCustomReports = debounce(this.getCustomReports, 900);
    if (this.selected_report_id){
        var report_id = this.selected_report_id;
        this.$store.commit("reports/selectReport", null);
            const url = make_url("/reports/" + report_id).toString();
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
            }).then(function(response){
                if (response) {
                    vm.selectReport(response.data);
                }
            }).catch(function(error){
                vm.showError(error);
            });
            return promise;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* increasing b-containers max-width for larger screens */
@media only screen and (min-width : 800px) {
   .container { max-width: 1400px; } 
}

.report-container-header {
    background:#F2F6FC;
    margin-left:-20px;
    margin-right:-20px;
    margin-top:-20px;
    padding:20px;
    border-top-left-radius:15px;
    border-top-right-radius:15px;
}

.nav-section {
    border-bottom: 1px solid #E6EEF9;
    margin-left: -15px;
    margin-right: -15px;
    padding-right: 35px;
    padding-left: 35px;
}

/* Styling the search bar group */
::v-deep .search-group {
    position: relative;
    margin-right: 20px;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    max-width:650px;
}
::v-deep .report-search-input, .input-group-text {
    height:50px;
    border: none;
    border-radius: 5px;
    background: white;
    color: #9CA3AD; 
}
::v-deep .report-search-input::placeholder {
    color: #9CA3AD;
    font-size:12px;
    opacity: 1;
}
::v-deep .b-dropdown .btn-secondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: #9CA3AD;
    background: white;
    font-size:12px;
    height:50px;
    min-width:150px;
    text-align:left;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, 
::v-deep .btn-secondary:not(:disabled):not(.disabled).active, 
::v-deep .show > .btn-secondary.dropdown-toggle {
    color:#519EFF !important;
    background-color: white;
}
::v-deep .b-dropdown .btn-secondary:focus {
    box-shadow:none !important;
}
</style>
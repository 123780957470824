<template>
<div id="report-document-assignments">
<b-modal id="report-document-assignments" @show="setForm" title="Report Document Assignments" hide-footer size="md">
<b-form>
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div>
        <div>
            <b-form-group
                label="Assign to a Practice:">
                <practice-multiselect 
                    v-if="!practiceLoading" 
                    v-model="selectedPractice" 
                    :multiple="false" 
                    :loading="practiceLoading">
                </practice-multiselect>
                <div v-else style="text-align:center">
                    <b-spinner></b-spinner>
                </div>
            </b-form-group>
        </div>
        <div>
            <b-form-group
                label="Assign to a Provider:">
                <provider-multiselect 
                    v-if="!providerLoading" 
                    v-model="selectedProvider" 
                    :multiple="false" 
                    :close-on-select="true"
                    :loading="providerLoading">
                </provider-multiselect>
                <div v-else style="text-align:center">
                    <b-spinner></b-spinner>
                </div>
            </b-form-group>
            </div>
        <div class="mt-4" style="text-align:right">
            <b-button @click="hideModal()" class="avo-basic-btn mr-2">
                Cancel
            </b-button>
            <b-button @click="submitDataSources()" :disabled="practiceLoading || providerLoading" variant="outline-primary" class="avo-primary-btn">
                Submit Report Document Assignments
            </b-button>
        </div>
    </div>
</b-form>
<div class="mt-4">
    <b-alert show class="avo-text-light mv-4" variant="info">
        Documents can only be assigned to one practice/provider. <br/>
        You may want to try assigning a report directly.
    </b-alert>
</div>
</b-modal>
</div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'
import PracticeMultiselect from '../multiselects/PracticeMultiselect.vue'
import ProviderMultiselect from '../multiselects/ProviderMultiselect.vue'

export default {
  name: 'ReportDocumentAssignmentModal',
  components: {
      PracticeMultiselect,
      ProviderMultiselect,
  },
  mixins: [alertsMixin],
  data: function(){
      return {
          selectedPractice: null,
          selectedProvider: null,

          originalPractice: null,
          originalProvider: null,

          practiceLoading: false,
          providerLoading: false,
      }
  },
  methods: {
    submitDataSources(){
        this.submitPractices();
    },
    submitPractices(){
        var selectedPracticeID = this.selectedPractice ? this.selectedPractice.practice_id : null;
        var originalPracticeID = this.originalPractice ? this.originalPractice.practice_id : null;
        if (selectedPracticeID != originalPracticeID){
            if (originalPracticeID){
                this.unassignPractice(selectedPracticeID);
            }
            else{
                this.assignPractice(selectedPracticeID);
            }
        }
        else{
            this.submitProviders();
        }
    },
    submitProviders(){
        var selectedProviderID = this.selectedProvider ? this.selectedProvider.provider_id : null;
        var originalProviderID = this.originalProvider ? this.originalProvider.provider_id : null;
        if (selectedProviderID != originalProviderID){
            if (originalProviderID){
                this.unassignProvider(selectedProviderID);
            }
            else{
                this.assignProvider(selectedProviderID);
            }
        }
        else{
            this.hideModal();
        }
    },
    unassignPractice(practice_id){
        const vm = this;
        const url = make_url("/documents/" + this.reportDocumentAssignmentID + "/practice");
        authenticated_request({
            method: "delete",
            url: url,
        }).then(function(response){
            if (response) {
                if (practice_id){
                    vm.assignPractice(practice_id);
                }
                else{
                    vm.submitProviders();
                }
            }
        }).catch(function(error){
            vm.showError(error);
        });
    },
    assignPractice(practice_id){
        const vm = this;
        const url = make_url("/documents/" + this.reportDocumentAssignmentID + "/practice");
        authenticated_request({
            method: "put",
            url: url,
            data: {
                practice_id: practice_id
            }
        }).then(function(response){
            if (response) {
                vm.submitProviders();
            }
        }).catch(function(error){
            vm.showError(error);
        });
    },
    unassignProvider(provider_id){
        const vm = this;
        const url = make_url("/documents/" + this.reportDocumentAssignmentID + "/provider");
        authenticated_request({
            method: "delete",
            url: url,
        }).then(function(response){
            if (response) {
                if (provider_id){
                    vm.assignProvider(provider_id);
                }
                else{
                    vm.hideModal();
                }
            }
        }).catch(function(error){
            vm.showError(error);
        });
    },
    assignProvider(provider_id){
        const vm = this;
        const url = make_url("/documents/" + this.reportDocumentAssignmentID + "/provider");
        authenticated_request({
            method: "put",
            url: url,
            data: {
                provider_id: provider_id
            }
        }).then(function(response){
            if (response) {
                vm.hideModal();
            }
        }).catch(function(error){
            vm.showError(error);
        });
    },
    setForm(){
        if (this.reportDocumentAssignmentID){
            this.practiceLoading = true;
            this.providerLoading = true;
            const vm = this;
            var url = make_url("/documents/" + this.reportDocumentAssignmentID + "/practice");
            authenticated_request({
                method: "get",
                url: url,
            }).then(function(response){
                if (response) {
                    vm.originalPractice = response.data;
                    vm.practiceLoading = false;
                }
            }).catch(function(error){
                vm.showError(error);
            });
            url = make_url("/documents/" + this.reportDocumentAssignmentID + "/provider");
            authenticated_request({
                method: "get",
                url: url,
            }).then(function(response){
                if (response) {
                    vm.originalProvider = response.data;
                    vm.providerLoading = false;
                }
            }).catch(function(error){
                vm.showError(error);
            });
        }
    },
    hideModal(){
        this.$bvModal.hide('report-document-assignments');
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
  computed: {
    reportDocumentAssignmentID: function(){
        return this.$store.state.reports.report_document_assignment_id;
    }
  },
  watch: {
    originalPractice: function(){
        this.selectedPractice = this.originalPractice ? Object.assign({}, this.originalPractice) : null;
    },
    originalProvider: function(){
        this.selectedProvider = this.originalProvider ? Object.assign({}, this.originalProvider) : null;
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>

</style>